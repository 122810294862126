import { render } from "./PushCampaignSettingForm.vue?vue&type=template&id=c168bbbe&scoped=true"
import script from "./PushCampaignSettingForm.vue?vue&type=script&lang=ts"
export * from "./PushCampaignSettingForm.vue?vue&type=script&lang=ts"

import "./PushCampaignSettingForm.vue?vue&type=style&index=0&id=c168bbbe&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-c168bbbe"

export default script
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab,QForm,QTabPanels,QTabPanel,QInput,QFooter,QToolbar,QBtn,QTooltip,QSpace});
